
#email {
    height: 20px;
    width: 60%;
    letter-spacing: 2px;
    background: none;
    border: 0px;
    border-bottom: 2px solid #222222;
    color: #1e1d23;
    display: block;
    margin: 13px auto;
    font-family: avenir;
    font-weight: 300;
    font-size: 14px;
  }
  #email:focus {
    border: 0px;
    outline: none;
    border-bottom: 2px solid #222222;
  }


  
  .subscribe_form {
    max-width: 570px;
    width: 100%;
    margin: 0 auto;
   
  }
  .subscribe_form:focus {
    outline: none;
  }
  .subscribe_form .form-control {
    height: 20px;
  width: 100%;
  background: none;
  border: 0px;
  border-bottom: 1px solid #192027;
  color: #1e1d23;
  display: block;
  margin: 20px auto;
  font-family: avenir;
  font-weight: 300;
  }
  .subscribe_form .form-control:focus {
    box-shadow: none;
  }
  .subscribe_form input {
    height: 44px;
  }
  .subscribe_form button {
    border: none;
    height: 38px;
    font-size: 14px;
    padding: 10px;
    background-color: transparent;
    color: #222222;
    margin: -1px;
    border-radius: 0;
    border: 2px solid #222222;
    width: 135px;
    text-transform: uppercase;
    position: relative;
    transition: all ease 0.3s;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    -ms-transition: all ease 0.3s;
  }
  .subscribe_form button:hover, .subscribe_form button:focus, .subscribe_form button:active, .subscribe_form button:visited {
    color: #fff;
    background-color: #827b5a;
    outline: none;
    transition: all ease 0.3s;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    -ms-transition: all ease 0.3s;
  }
  .subscribe_form button:hover:before, .subscribe_form button:focus:before, .subscribe_form button:active:before, .subscribe_form button:visited:before {
    background-color: #827b5a;
    transition: all ease 0.3s;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    -ms-transition: all ease 0.3s;
  }
  .subscribe_form button:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    height: 1px;
    background-color: #222222;
    width: 100%;
    transition: all ease 0.3s;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    -ms-transition: all ease 0.3s;
  }
  .subscribe_form .input-group-btn:last-child > .btn,
  .subscribe_form .input-group-btn:last-child > .btn-group {
    z-index: 5;
    margin-left: 0px;
  }
/* fOOTER MAIN DESIGN..................................................................................*/
.ul-parent {
  padding: 0;
  margin: 0;
}
.li-style {
  list-style: none;
}
.a-style:focus,
.a-style:hover {
  text-decoration: none;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}
.a-style:focus {
  outline: 0;
}

.p-style {
  font-size: 16px;
  line-height: 30px;
  color: #222222;
  font-weight: 900;
  font-family: 'avenir';
}

.a-style {
  color: #827b5a;
}
.no-padding {
  padding: 0 !important;
}

.footer-big {
  padding: 80px 0 0px 0;
}
.footer-big .footer-widget {
  margin-bottom: 2px;
  text-align: start;
}
.footer--light {
  background: #fff;
  margin-top: -16px;
}
.footer-big .footer-menu ul li a,
.footer-big p,
.footer-big ul li {
  color: #222222;
}
.footer-menu {
  padding-left: 48px;
}
.footer-menu ul li a {
  font-size: 15px;
  line-height: 32px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.footer-menu ul li a:hover {
  color: #827b5a;
  text-decoration: none
}
.footer-menu--1 {
  width: 100%;
 padding-left: 85px;
}

.footer-widget-two {
  margin-top: 5px;
}
.footer-widget-title {
  line-height: 42px;
  margin-bottom: 10px;
  font-size: 18px;
}

.footer-widget-title-main {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 100;
}

.footer-widget-title-right {
  line-height: 42px;
  text-transform: none;
  letter-spacing: 0.25px;
  font-family: 300;
  font-size: 14px;
}

.footer-widget-title-left {
  line-height: 42px;
  text-transform: none;
  letter-spacing: 0.25px;
  font-family: 300;
  font-size: 14px;
  text-align: end;
}
.mini-footer {
  background: #192027;
  text-align: center;
  padding: 32px 0;
}
.mini-footer p {
  margin: 0;
  line-height: 26px;
  font-size: 15px;
  color: #999;
}
.mini-footer p a {
  color: #827b5a;
}
.mini-footer p a:hover {
  color: #34bfa3;
}
.widget-about img {
  display: block;
  margin-bottom: 30px;
}
.widget-about p {
  font-weight: 400;
}
.widget-about .contact-details {
  margin: 30px 0 0 0;
}
.widget-about .contact-details li {
  margin-bottom: 10px;
}
.widget-about .contact-details li:last-child {
  margin-bottom: 0;
}
.widget-about .contact-details li span {
  padding-right: 12px;
}
.widget-about .contact-details li a {
  color: #827b5a;
}
@media (max-width: 991px) {
  .footer-menu {
    padding-left: 0;
  }
}

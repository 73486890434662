@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/utilities";


.leaderboard-position-text {
  font-size: 1.5em;
  font-family: "Raleway";
  font-weight: "300";
}

@include media-breakpoint-up(md) {
  .leaderboard-position-text {
    font-size: 4em;
  }
}

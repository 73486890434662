
  .container {
    padding: 2rem 0rem;
  }
  
  .table-image thead td, .table-image thead th {
    border: 0;
    color: #666;
    font-size: 0.8rem;
  }
  .table-image td, .table-image th {
    vertical-align: middle;
    text-align: center;
  }
  .table-image td.qty, .table-image th.qty {
    max-width: 2rem;
  }
  
  .price {
    margin-left: 1rem;
  }
  
  .modal-footer {
    padding-top: 0rem;
  }
  /* header */
  

  
  .ul-style2 {
    margin: 0;
    padding: 0;
    list-style: none;
   overflow: hidden;
   
  }
  
 
 
	@media screen and (min-width: 992px) and (max-width: 2560px) {
    .ul-mobile {
      display: none
    }
  }
  
  .header-home li a:hover,
  .header-home .menu-btn:hover {
    background-color: #827b5a;
  }
  
  .header-home .logo {
    display: block;
    float: left;
    font-size: 2em;
    padding: 10px 20px;
    text-decoration: none;
  }
  
  /* menu */
  
  .header-home .menu {
    clear: both;
    max-height: 0;
    transition: max-height 0.2s ease-out;
  }
  
  /* menu icon */
  
  .header-home .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 20px;
    position: relative;
    user-select: none;
  }
  
  .header-home .menu-icon .navicon {
    background: #fff;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
  }
  
  .header-home .menu-icon .navicon:before,
  .header-home .menu-icon .navicon:after {
    background: #fff;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }
  
  .header-home .menu-icon .navicon:before {
    top: 5px;
  }
  
  .header-home .menu-icon .navicon:after {
    top: -5px;
  }
  
  /* menu btn */
  
  .header-home .menu-btn {
    display: none;
  }
  
  .header-home .menu-btn:checked ~ .menu {
    max-height: 240px;
  }
  
  .header-home .menu-btn:checked ~ .menu-icon .navicon {
    background: #fff;
  }
  
  .header-home .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
  }
  
  .header-home .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
  }
  
  .header-home .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .header-home .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }
  
  /* 48em = 768px */
  
  @media (min-width: 48em) {
    .header-home li {
      float: right;
    }
    .header-home li a {
      padding: 20px 30px;
    }
    .header-home .menu {
      clear: none;
      float: right;
      max-height: none;
    }
    .header-home .menu-icon {
      display: none;
    }
  }

  @media screen and (min-width: 967px) and (max-width: 1127px) {
  
  }
  
  @media screen and (max-width: 967px) {
    .header-home .logo {
        display: block;
        float: right;
        font-size: 2em;
        padding: 10px 20px;
        text-decoration: none;
      }
      
      .header-home .menu-icon {
        cursor: pointer;
        display: inline-block;
        float: left;
        padding: 28px 20px;
        position: relative;
        user-select: none;
      }
  }
  
  
  /* submit form */
 /*
#email {
 
  height: 20px;
  width: 60%;
  letter-spacing: 2px;
  background: none;
  border: 0px;
  border-bottom: 2px solid #222222;
  color: #1e1d23;
  display: block;
  margin: 13px auto;
  font-family: avenir;
  font-weight: 300;
  font-size: 14px;
  
}
*/
#email:focus {
  border: 0px;
  outline: none;
  border-bottom: 2px solid #222222;
}



.subscribe_form {
  max-width: 570px;
  width: 100%;
  margin: 0 auto;
 
}
.subscribe_form:focus {
  outline: none;
}

.subscribe_form input {
  height: 44px;
}

.subscribe_form button {

  transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  -ms-transition: all ease 0.3s;
}

.subscribe_form button:hover, .subscribe_form button:focus, .subscribe_form button:active, .subscribe_form button:visited {
  color: #fff;
  background-color: #827b5a;
  outline: none;
  transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  -ms-transition: all ease 0.3s;
}
.subscribe_form button:hover:before, .subscribe_form button:focus:before, .subscribe_form button:active:before, .subscribe_form button:visited:before {
  background-color: #827b5a;
  transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  -ms-transition: all ease 0.3s;
}
.subscribe_form button:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  height: 1px;
  background-color: #222222;
  width: 100%;
  transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  -ms-transition: all ease 0.3s;
}
.subscribe_form .input-group-btn:last-child > .btn,
.subscribe_form .input-group-btn:last-child > .btn-group {
  z-index: 5;
  margin-left: 0px;
}


@import "~react-component-countdown-timer/lib/styles.css";

.drop-timer {
  display: flex;
}

.count,
.split {
  font-family: "Raleway";
  font-weight: "300";
}

.count {
  display: inline-block;
  width: 1.25em;
  margin-left: 0.15em;
}

/*  vote-section-mobile div_three*/
body {
  margin: 0;
  font-family: "Avenir Black";
  letter-spacing: 0.005em;
  scroll-behavior: smooth;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

#vote:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, 0.3);
}

#pitch-text {
  font-size: 2.2rem;
}

.wrapper {
  display: table;
  height: 100%;
  width: 100%;
}

.container-fostrap {
  display: table-cell;
  padding: 1em;
  text-align: center;
  vertical-align: middle;
}

h1.heading {
  color: #fff;
  font-size: 1.15em;
  font-weight: 500;
  margin: 0 0 0.8em;
  color: #0a0a0a;
}
@media (min-width: 450px) {
  h1.heading {
    font-size: 3.55em;
  }
}
@media (min-width: 760px) {
  h1.heading {
    font-size: 3.05em;
  }
}
@media (min-width: 900px) {
  h1.heading {
    font-size: 3.25em;
    margin: 0 0 0.9em;
    margin-top: 50px;
  }
  .content {
    margin-top: -50px;
  }
}

.timer {
  flex-direction: column;
  justify-content: center;
}

.modal-title {
  padding: 5px;
  font-size: 24px;
}

.modal-div {
  padding: 30px;
}

.modal-vote-div {
  padding: 40px;
}

.modal-vote-btn {
  margin-top: 20px;
  margin-left: 270px;
}

.app {
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
.btn-text {
  color: white;
  text-decoration: none !important;
}

.btn-text:hover {
  color: white;
  text-decoration: none !important;
}

.div_one {
  height: 450px;
}

.title {
  font-size: 52px;
  padding: 1em;
  font-family: "Bauer Bodoni" !important;
  font-weight: bold;
  font-style: italic;
}

.item {
  color: white;
  text-decoration: none !important;
}
.item:hover {
  color: #827b5a;
}

.logo-light {
  margin-left: 20px;
  margin-top: 5px;
}

.div_two {
  background-color: grey;
}
/*79705db3 logo-light*/
.bg-overlay {
  background: linear-gradient(rgba(121, 112, 93, 0.7), rgba(121, 112, 93, 0.7)),
    url("/dapper_men.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 0px;
  margin: 0px;
  /*height: 600px;*/
}

.vote-btn {
  margin-top: 20px;
  margin-left: 170px;
}

.div_three {
  background-color: #171717;
  height: 520px;
  clear: both;
}

.services-section {
  padding: 3em;
}

.services-header {
  color: rgb(107, 171, 172);
  padding-bottom: 1.5em;
  text-align: center;
  margin: auto;
  display: block;
}

.services-paragraph {
  padding-top: 1em;
  font-size: 18px;
  font-weight: lighter;
  color: #ffffff;
  text-align: center;
  margin: auto;
  display: block;
}

/* Container holding the image and the text title  */

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

/* Top right text */
.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

/* Bottom right text */
.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.box {
  margin: 50px auto;
  margin-top: 70px;
  width: 300px;
  height: 50px;
}

.box2 {
  margin: 10px auto;
  margin-top: 65px;
  margin-left: 65px;
  width: 300px;
  height: 50px;
}

.container-4 {
  margin-left: -100px;
  overflow: hidden;
  width: 400px;
  vertical-align: middle;
  white-space: nowrap;
}

.container-4 input#search {
  width: 350px;
  height: 50px;
  background: #e9e0cfb3;
  border-top: 1px solid #0a0a0a;
  border-bottom: 1px solid #0a0a0a;
  font-size: 10pt;
  float: left;
  color: #000000;
  padding-left: 15px;
}

.container-4 button.icon {
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  border: 1px solid #0a0a0a;
  background: #0a0a0a;
  height: 50px;
  width: 100px;
  color: #fff;
  opacity: 1;
  font-size: 10pt;
  padding-top: 15px;
  margin-left: -50px;
}

.container-4:hover button.icon,
.container-4:active button.icon,
.container-4:focus button.icon {
  outline: none;
  opacity: 1;
  margin-left: -50px;
}

.container-4:hover button.icon:hover {
  background: #0a0a0a;
  color: #fff;
}

.footer-title {
  font-size: 48px;
  margin-top: 5px;
}

.footer-text {
  font-size: 16px;
  margin-left: 20px;
}

.footer-text2 {
  font-size: 14px;
}

.footer-text3 {
  font-size: 12px;
}

.footer-logo {
  margin-top: 60px;
  width: 80px;
}

.phone {
  margin-top: 50px;

  width: 250px;
  height: 500px;
}

.about-title {
  font-size: 48px;
  padding: 1.5em;
  color: #fff;
  font-weight: bold;
}

.about-text {
}

.item-other {
  color: #0a0a0a;
  text-decoration: none !important;
}
.item-other:hover {
  color: rgb(202, 197, 197);
}

.about-div_one_text {
  font-size: 30px;
  text-align: center;
}

.about-people {
  height: 500px;
}

.about-card {
  margin-top: -20px;
}

.about-people-title {
  text-align: center;
  font-size: 40px;
  margin-top: 60px;
}

.about-section {
  border: 1px solid black;
  margin-top: 20px;
}

.about-inner-section {
  border: 1px solid black;
  padding: 40px;
  margin-top: 100px;
  width: 50vh;
  height: 60vh;
}

.about-section img {
  width: 150px;
  height: 150px;
  margin: auto;
  display: block;
}

.about-header {
  color: rgb(107, 171, 172);
  padding-bottom: 1.5em;
  text-align: center;
  margin: auto;
  display: block;
}

.about-paragraph {
  padding-top: 1em;
  font-size: 18px;
  font-weight: lighter;
  color: #0a0a0a;
  text-align: center;
  margin: auto;
  display: block;
}

.vote-title {
  color: #171717;
  font-family: "Avenir Black";
  padding: 1vh;
  margin-left: 75vh;
  text-align: center;
}

.vote-arrow-left {
  padding: 16vh;
  margin-left: 20vh;
  font-size: 60px;
  margin-top: 4vh;
  position: fixed;
}

.vote-arrow-right {
  margin-top: 16vh;
  padding: 4vh;
  margin-left: -29vh;
  font-size: 60px;
  position: fixed;
}
/*
.vote-thumbs-down {
	font-size: 50px;
	margin-left: 16vh;
	 margin-top: -8vh;
}

.vote-thumbs-up {
   font-size: 50px;
   margin-left: 9vh;
	margin-top: 2vh;
}
*/

.vote-img {
  margin-left: 38vh;
  width: 50vh;
}

.vote-section-mobile {
  display: none;
}

.vote-section {
  background-color: #fff;
}

/*
.vote-section {
	background: linear-gradient(rgba(20, 20, 20, 0.7), rgba(27, 20, 20, 0.7)), url('/sewing_machine.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	height: 100%;
}
*/
.nav-mobile {
  display: initial;
}

.about-top-card {
  background-color: #171717;
  height: 70vh;
  padding: 3em;
  text-align: center;
}

.cutso-mission-header {
  color: white !important;
  font-size: 58px !important;
}

.div_three_mobile-flow {
  display: none;
}

/*###Desktops, big landscape tablets and laptops(Large, Extra large)####*/
@media screen and (min-width: 1200px) {
  /*Style*/

  body {
    margin: 0;
  }

  .timer {
    flex-direction: column;
    justify-content: center;
  }

  .modal-title {
    padding: 5px;
    font-size: 24px;
  }

  .modal-div {
    padding: 30px;
  }

  .v-mobile {
    display: none;
  }

  .modal-vote-div {
    padding: 40px;
  }

  .modal-vote-btn {
    margin-top: 20px;
    margin-left: 270px;
  }

  .app {
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }
  .btn-text {
    color: white;
    text-decoration: none !important;
  }

  .btn-text:hover {
    color: white;
    text-decoration: none !important;
  }

  .div_one {
    height: 450px;
  }

  .title {
    font-size: 52px;
    padding: 1em;
    font-family: "Bauer Bodoni" !important;
    font-weight: bold;
    font-style: italic;
  }

  .item {
    color: white;
    text-decoration: none !important;
  }
  .item:hover {
    color: #827b5a;
  }

  .logo-light {
    margin-left: 20px;
    margin-top: 5px;
  }

  .div_two {
    background-color: grey;
  }
  /*79705db3*/
  .bg-overlay {
    background: linear-gradient(
        rgba(121, 112, 93, 0.7),
        rgba(121, 112, 93, 0.7)
      ),
      url("/dapper_men.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 0px;
    margin: 0px;
    /*height: 600px;*/
  }

  .text1 {
    margin-left: -50px;
    font-size: 26px;
    margin-top: 150px;
    font-family: "Avenir Black";
  }

  .text2 {
    font-size: 26px;
    margin-top: 20px;
    margin-left: -30px;
    font-family: "Avenir Black";
  }

  .text3 {
    font-size: 26px;
    margin-top: 20px;
    margin-left: -50px;
    font-family: "Avenir Black";
  }

  .vote-btn {
    margin-top: 20px;
    margin-left: 200px;
  }

  .div_three {
    background-color: #000;
    height: 800px;
    clear: both;
  }

  .services-section {
    padding: 5em;
  }

  .services-section img {
    width: 150px;
    height: 150px;
    margin: auto;
    display: block;
    object-fit: contain;
  }

  .services-section-img {
    width: 1100px;
    height: 800px;
    margin: auto;
    display: block;
    object-fit: contain;
  }

  .services-header {
    color: rgb(107, 171, 172);
    padding-bottom: 1.5em;
    text-align: center;
    margin: auto;
    display: block;
  }

  .services-paragraph {
    padding-top: 1em;
    font-size: 18px;
    font-weight: lighter;
    color: #ffffff;
    text-align: center;
    margin: auto;
    display: block;
  }

  /* Container holding the image and the text title vote-bt  */

  /* Bottom left text */
  .bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
  }

  /* Top left text */
  .top-left {
    position: absolute;
    top: 8px;
    left: 16px;
  }

  /* Top right text */
  .top-right {
    position: absolute;
    top: 8px;
    right: 16px;
  }

  /* Bottom right text */
  .bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
  }

  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .box {
    margin: 50px auto;
    margin-top: 85px;
    width: 400px;
    height: 50px;
  }

  .box2 {
    margin: 10px auto;
    margin-top: 65px;
    margin-left: 65px;
    width: 300px;
    height: 50px;
  }

  .container-4 {
    margin-left: -100px;
    overflow: hidden;
    width: 600px;
    vertical-align: middle;
    white-space: nowrap;
  }

  .container-4 input#search {
    width: 350px;
    height: 50px;
    background: #e9e0cfb3;
    border-top: 1px solid #0a0a0a;
    border-bottom: 1px solid #0a0a0a;
    font-size: 10pt;
    float: left;
    color: #000000;
    padding-left: 15px;
  }

  .container-4 button.icon {
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    border: 1px solid #0a0a0a;
    background: #0a0a0a;
    height: 50px;
    width: 200px;
    color: #fff;
    opacity: 1;
    font-size: 10pt;
    padding-top: 15px;
    margin-left: -50px;
  }

  .container-4:hover button.icon,
  .container-4:active button.icon,
  .container-4:focus button.icon {
    outline: none;
    opacity: 1;
    margin-left: -50px;
  }

  .container-4:hover button.icon:hover {
    background: #0a0a0a;
    color: #fff;
  }

  .footer-title {
    font-size: 48px;
    margin-top: 5px;
  }

  .footer-text {
    font-size: 16px;
    margin-left: 20px;
  }

  .footer-text2 {
    font-size: 14px;
  }

  .footer-text3 {
    font-size: 12px;
  }

  .footer-logo {
    margin-top: 60px;
    width: 80px;
  }

  .phone {
    margin-top: 50px;

    width: 250px;
    height: 500px;
  }

  .about-title {
    font-size: 48px;
    color: #fff;
  }

  .item-other {
    color: #0a0a0a;
    text-decoration: none !important;
  }
  .item-other:hover {
    color: rgb(202, 197, 197);
  }

  .about-div_one_text {
    font-size: 30px;
    text-align: center;
  }

  .about-people {
    height: 180vh;
  }

  .about-card {
    margin-top: -20px;
  }

  .about-people-title {
    text-align: center;
    font-size: 40px;
    margin-top: 60px;
  }

  .about-section {
    border: 1px solid black;
    margin-top: 20px;
  }

  .about-inner-section {
    border: 1px solid black;
    margin: 2vh;
    width: 2vh;
    height: 30vh;
  }

  .about-people-name {
    padding: 20px;
    font-size: 24px;
  }

  .about-people-location {
    font-size: 16px;
  }

  .about-people-reason {
    font-size: 18px;
    padding: 12px;
  }

  .about-section img {
    width: 150px;
    height: 150px;
    margin: auto;
    display: block;
  }

  .about-header {
    color: rgb(107, 171, 172);
    padding-bottom: 1.5em;
    text-align: center;
    margin: auto;
    display: block;
  }

  .about-paragraph {
    padding-top: 1em;
    font-size: 18px;
    font-weight: lighter;
    color: #0a0a0a;
    text-align: center;
    margin: auto;
    display: block;
  }

  .vote-title {
    font-size: 44px;
    color: #171717;
    font-family: "Avenir Black";
    margin-left: 85vh;
    text-align: center;
  }

  .vote-title-two {
    font-size: 44px;
    color: #171717;
    font-family: "Avenir Black";
    margin-left: 82vh;
    text-align: center;
  }

  .vote-title-three {
    font-size: 44px;
    color: #171717;
    font-family: "Avenir Black";
    margin-left: 78vh;
    text-align: center;
  }

  .vote-arrow-left {
    padding: 16vh;
    margin-left: 20vh;
    font-size: 60px;
    margin-top: 4vh;
    position: fixed;
  }

  .vote-arrow-right {
    margin-top: 16vh;
    padding: 4vh;
    margin-left: -29vh;
    font-size: 60px;
    position: fixed;
  }

  .vote-thumbs-down {
    font-size: 50px;
    margin-left: 25vh;
    margin-top: 57vh;
    position: absolute;
  }

  .vote-thumbs-up {
    font-size: 50px;
    margin-left: 8vh;
    margin-top: 57vh;
    position: absolute;
  }

  .vote-img {
    margin-left: 32vh;
    margin-top: 20px;
  }

  .vote-section-mobile {
    display: none;
  }

  .vote-loading {
    text-align: center;
    margin-left: 57vh;
    margin-top: 20vh;
  }

  .nav-mobile {
    display: initial;
  }
  .about-top-card {
    background-color: #171717;
    height: 70vh;
    padding: 3em;
    text-align: center;
  }

  .div_three_mobile-flow {
    display: none;
  }
  .web-flow {
    display: initial;
    background-color: "black";
  }
}
/*###Desktops, big landscape tablets and laptops(Large, Extra large)####*/
@media screen and (min-width: 1069px) {
  /*Style*/

  body {
    margin: 0;
  }

  .timer {
    flex-direction: column;
    justify-content: center;
  }

  .modal-title {
    padding: 5px;
    font-size: 24px;
  }

  .modal-div {
    padding: 30px;
  }

  .v-mobile {
    display: none;
  }

  .modal-vote-div {
    padding: 40px;
  }

  .modal-vote-btn {
    margin-top: 20px;
    margin-left: 270px;
  }

  .app {
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }
  .btn-text {
    color: white;
    text-decoration: none !important;
  }

  .btn-text:hover {
    color: white;
    text-decoration: none !important;
  }

  .div_one {
    height: 450px;
  }

  .title {
    font-size: 52px;
    padding: 1em;
    font-family: "Bauer Bodoni" !important;
    font-weight: bold;
    font-style: italic;
  }

  .item {
    color: white;
    text-decoration: none !important;
  }
  .item:hover {
    color: #827b5a;
  }

  .logo-light {
    margin-left: 20px;
    margin-top: 5px;
  }

  .div_two {
    background-color: grey;
  }
  /*79705db3*/
  .bg-overlay {
    background: linear-gradient(
        rgba(121, 112, 93, 0.7),
        rgba(121, 112, 93, 0.7)
      ),
      url("/dapper_men.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 0px;
    margin: 0px;
    /*height: 600px;*/
  }

  .text1 {
    margin-left: -50px;
    font-size: 26px;
    margin-top: 150px;
    font-family: "Avenir Black";
  }

  .text2 {
    font-size: 26px;
    margin-top: 20px;
    margin-left: -30px;
    font-family: "Avenir Black";
  }

  .text3 {
    font-size: 26px;
    margin-top: 20px;
    margin-left: -50px;
    font-family: "Avenir Black";
  }

  .vote-btn {
    margin-top: 20px;
    margin-left: 200px;
  }

  .div_three {
    background-color: #000;
    height: 800px;
    clear: both;
  }

  .services-section {
    padding: 5em;
  }

  .services-section img {
    width: 150px;
    height: 150px;
    margin: auto;
    display: block;
    object-fit: contain;
  }

  .services-section-img {
    width: 1100px;
    height: 800px;
    margin: auto;
    display: block;
    object-fit: contain;
  }

  .services-header {
    color: rgb(107, 171, 172);
    padding-bottom: 1.5em;
    text-align: center;
    margin: auto;
    display: block;
  }

  .services-paragraph {
    padding-top: 1em;
    font-size: 18px;
    font-weight: lighter;
    color: #ffffff;
    text-align: center;
    margin: auto;
    display: block;
  }

  /* Container holding the image and the text title vote-bt  */

  /* Bottom left text */
  .bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
  }

  /* Top left text */
  .top-left {
    position: absolute;
    top: 8px;
    left: 16px;
  }

  /* Top right text */
  .top-right {
    position: absolute;
    top: 8px;
    right: 16px;
  }

  /* Bottom right text */
  .bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
  }

  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .box {
    margin: 50px auto;
    margin-top: 85px;
    width: 400px;
    height: 50px;
  }

  .box2 {
    margin: 10px auto;
    margin-top: 65px;
    margin-left: 65px;
    width: 300px;
    height: 50px;
  }

  .container-4 {
    margin-left: -100px;
    overflow: hidden;
    width: 600px;
    vertical-align: middle;
    white-space: nowrap;
  }

  .container-4 input#search {
    width: 350px;
    height: 50px;
    background: #e9e0cfb3;
    border-top: 1px solid #0a0a0a;
    border-bottom: 1px solid #0a0a0a;
    font-size: 10pt;
    float: left;
    color: #000000;
    padding-left: 15px;
  }

  .container-4 button.icon {
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    border: 1px solid #0a0a0a;
    background: #0a0a0a;
    height: 50px;
    width: 200px;
    color: #fff;
    opacity: 1;
    font-size: 10pt;
    padding-top: 15px;
    margin-left: -50px;
  }

  .container-4:hover button.icon,
  .container-4:active button.icon,
  .container-4:focus button.icon {
    outline: none;
    opacity: 1;
    margin-left: -50px;
  }

  .container-4:hover button.icon:hover {
    background: #0a0a0a;
    color: #fff;
  }

  .footer-title {
    font-size: 48px;
    margin-top: 5px;
  }

  .footer-text {
    font-size: 16px;
    margin-left: 20px;
  }

  .footer-text2 {
    font-size: 14px;
  }

  .footer-text3 {
    font-size: 12px;
  }

  .footer-logo {
    margin-top: 60px;
    width: 80px;
  }

  .phone {
    margin-top: 50px;

    width: 250px;
    height: 500px;
  }

  .about-title {
    font-size: 48px;
    color: #fff;
  }

  .item-other {
    color: #0a0a0a;
    text-decoration: none !important;
  }
  .item-other:hover {
    color: rgb(202, 197, 197);
  }

  .about-div_one_text {
    font-size: 30px;
    text-align: center;
  }

  .about-people {
    height: 180vh;
  }

  .about-card {
    margin-top: -20px;
  }

  .about-people-title {
    text-align: center;
    font-size: 40px;
    margin-top: 60px;
  }

  .about-section {
    border: 1px solid black;
    margin-top: 20px;
  }

  .about-inner-section {
    border: 1px solid black;
    padding: 0px;
    width: 50vh;
    height: 58vh;
  }

  .about-people-name {
    padding: 20px;
    font-size: 24px;
  }

  .about-people-location {
    font-size: 16px;
  }

  .about-people-reason {
    font-size: 18px;
    padding: 12px;
  }

  .about-section img {
    width: 150px;
    height: 150px;
    margin: auto;
    display: block;
  }

  .about-header {
    color: rgb(107, 171, 172);
    padding-bottom: 1.5em;
    text-align: center;
    margin: auto;
    display: block;
  }

  .about-paragraph {
    padding-top: 1em;
    font-size: 18px;
    font-weight: lighter;
    color: #0a0a0a;
    text-align: center;
    margin: auto;
    display: block;
  }

  .vote-title {
    font-size: 44px;
    color: #171717;
    font-family: "Avenir Black";
    margin-left: 85vh;
    text-align: center;
  }

  .vote-title-two {
    font-size: 44px;
    color: #171717;
    font-family: "Avenir Black";
    margin-left: 82vh;
    text-align: center;
  }

  .vote-title-three {
    font-size: 44px;
    color: #171717;
    font-family: "Avenir Black";
    margin-left: 78vh;
    text-align: center;
  }

  .vote-arrow-left {
    padding: 16vh;
    margin-left: 20vh;
    font-size: 60px;
    margin-top: 4vh;
    position: fixed;
  }

  .vote-arrow-right {
    margin-top: 16vh;
    padding: 4vh;
    margin-left: -29vh;
    font-size: 60px;
    position: fixed;
  }

  .vote-thumbs-down {
    font-size: 50px;
    margin-left: 25vh;
    margin-top: 57vh;
    position: absolute;
  }

  .vote-thumbs-up {
    font-size: 50px;
    margin-left: 8vh;
    margin-top: 57vh;
    position: absolute;
  }

  .vote-img {
    margin-left: 32vh;
    margin-top: 20px;
  }

  .vote-section-mobile {
    display: none;
  }

  .vote-loading {
    text-align: center;
    margin-left: 57vh;
    margin-top: 20vh;
  }

  .nav-mobile {
    display: initial;
  }
  .about-top-card {
    background-color: #171717;
    height: 70vh;
    padding: 3em;
    text-align: center;
  }

  .div_three_mobile-flow {
    display: none;
  }
  .web-flow {
    display: initial;
    background-color: "black";
  }
}
/*  media queries for various devices */

/*###Desktops, big landscape tablets and laptops(Large, Extra large)####*/
@media screen and (min-width: 1024px) and (max-width: 1068px) {
  /*Style*/

  body {
    margin: 0;
  }

  .timer {
    flex-direction: column;
    justify-content: center;
  }

  .modal-title {
    padding: 5px;
    font-size: 24px;
  }

  .modal-div {
    padding: 30px;
  }

  .v-mobile {
    display: none;
  }

  .modal-vote-div {
    padding: 40px;
  }

  .modal-vote-btn {
    margin-top: 20px;
    margin-left: 270px;
  }

  .app {
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }
  .btn-text {
    color: white;
    text-decoration: none !important;
  }

  .btn-text:hover {
    color: white;
    text-decoration: none !important;
  }

  .div_one {
    height: 450px;
  }

  .title {
    font-size: 52px;
    padding: 1em;
    font-family: "Bauer Bodoni" !important;
    font-weight: bold;
    font-style: italic;
  }

  .item {
    color: white;
    text-decoration: none !important;
  }
  .item:hover {
    color: #827b5a;
  }

  .logo-light {
    margin-left: 20px;
    margin-top: 5px;
  }

  .div_two {
    background-color: grey;
  }
  /*79705db3 vote-section*/
  .bg-overlay {
    background: linear-gradient(
        rgba(121, 112, 93, 0.7),
        rgba(121, 112, 93, 0.7)
      ),
      url("/dapper_men.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 0px;
    margin: 0px;
    /*height: 600px;*/
  }

  .text1 {
    margin-left: -45px;
    font-size: 26px;
    margin-top: 150px;
    font-family: "Avenir Black";
  }

  .text2 {
    font-size: 26px;
    margin-top: 20px;
    margin-left: -30px;
    font-family: "Avenir Black";
  }

  .text3 {
    font-size: 26px;
    margin-top: 20px;
    margin-left: -50px;
    font-family: "Avenir Black";
  }

  .vote-btn {
    margin-top: 20px;
    margin-left: 200px;
  }

  .div_three {
    background-color: #000;
    height: 700px;
    clear: both;
  }

  .services-section {
    padding: 5em;
  }

  .services-section-img {
    width: 148vh;
    height: 100vh;
    text-align: center;
    margin: auto;
    display: block;
    object-fit: contain;
  }

  .services-header {
    color: rgb(107, 171, 172);
    padding-bottom: 1.5em;
    text-align: center;
    margin: auto;
    display: block;
  }

  .services-paragraph {
    padding-top: 1em;
    font-size: 18px;
    font-weight: lighter;
    color: #ffffff;
    text-align: center;
    margin: auto;
    display: block;
  }

  /* Container holding the image and the text title  */

  /* Bottom left text */
  .bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
  }

  /* Top left text */
  .top-left {
    position: absolute;
    top: 8px;
    left: 16px;
  }

  /* Top right text */
  .top-right {
    position: absolute;
    top: 8px;
    right: 16px;
  }

  /* Bottom right text */
  .bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
  }

  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .box {
    margin: 50px auto;
    margin-top: 85px;
    width: 400px;
    height: 50px;
  }

  .box2 {
    margin: 10px auto;
    margin-top: 65px;
    margin-left: 65px;
    width: 300px;
    height: 50px;
  }

  .container-4 {
    margin-left: -100px;
    overflow: hidden;
    width: 600px;
    vertical-align: middle;
    white-space: nowrap;
  }

  .container-4 input#search {
    width: 350px;
    height: 50px;
    background: #e9e0cfb3;
    border-top: 1px solid #0a0a0a;
    border-bottom: 1px solid #0a0a0a;
    font-size: 10pt;
    float: left;
    color: #000000;
    padding-left: 15px;
  }

  .container-4 button.icon {
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    border: 1px solid #0a0a0a;
    background: #0a0a0a;
    height: 50px;
    width: 200px;
    color: #fff;
    opacity: 1;
    font-size: 10pt;
    padding-top: 15px;
    margin-left: -50px;
  }

  .container-4:hover button.icon,
  .container-4:active button.icon,
  .container-4:focus button.icon {
    outline: none;
    opacity: 1;
    margin-left: -50px;
  }

  .container-4:hover button.icon:hover {
    background: #0a0a0a;
    color: #fff;
  }

  .footer-title {
    font-size: 48px;
    margin-top: 5px;
  }

  .footer-text {
    font-size: 16px;
    margin-left: 20px;
  }

  .footer-text2 {
    font-size: 14px;
  }

  .footer-text3 {
    font-size: 12px;
  }

  .footer-logo {
    margin-top: 60px;
    width: 80px;
  }

  .phone {
    margin-top: 50px;

    width: 250px;
    height: 500px;
  }

  .about-title {
    font-size: 48px;
    padding: 1.5em;
    color: #fff;
    font-family: "Bauer Bodoni";
    font-weight: bold;
    font-style: italic;
  }

  .item-other {
    color: #0a0a0a;
    text-decoration: none !important;
  }
  .item-other:hover {
    color: rgb(202, 197, 197);
  }

  .about-div_one_text {
    font-size: 30px;
    text-align: center;
  }

  .about-people {
    height: 150vh;
  }

  .about-card {
    margin-top: -20px;
  }

  .about-people-title {
    text-align: center;
    font-size: 40px;
    margin-top: 60px;
  }

  .about-section {
    border: 1px solid black;
    margin-top: 20px;
  }

  .about-inner-section {
    border: 1px solid black;
    margin: 40px;
    width: 45vh;
    height: 60vh;
  }

  .about-people-location {
    font-size: 14px;
  }

  .about-people-reason {
    font-size: 16px;
  }

  .about-section img {
    width: 150px;
    height: 150px;
    margin: auto;
    display: block;
  }

  .about-header {
    color: rgb(107, 171, 172);
    padding-bottom: 1.5em;
    text-align: center;
    margin: auto;
    display: block;
  }

  .about-paragraph {
    padding-top: 1em;
    font-size: 18px;
    font-weight: lighter;
    color: #0a0a0a;
    text-align: center;
    margin: auto;
    display: block;
  }

  .vote-title {
    font-size: 44px;
    color: #171717;
    font-family: "Avenir Black";
    margin-left: 85vh;
    text-align: center;
  }

  .vote-title-two {
    font-size: 44px;
    color: #171717;
    font-family: "Avenir Black";
    margin-left: 82vh;
    text-align: center;
  }

  .vote-title-three {
    font-size: 44px;
    color: #171717;
    font-family: "Avenir Black";
    margin-left: 78vh;
    text-align: center;
  }

  .vote-arrow-left {
    padding: 16vh;
    margin-left: 5vh;
    font-size: 60px;
    margin-top: 4vh;
    position: fixed;
  }

  .vote-arrow-right {
    margin-top: 16vh;
    padding: 4vh;
    margin-left: 9vh;
    font-size: 60px;
    position: fixed;
  }

  .vote-thumbs-down {
    font-size: 50px;
    margin-left: 27vh;
    margin-top: 57vh;
    position: absolute;
  }

  .vote-thumbs-up {
    font-size: 50px;
    margin-left: 19vh;
    margin-top: 57vh;
    position: absolute;
  }

  .vote-img {
    margin-left: 32vh;
    margin-top: 20px;
  }

  .vote-section-mobile {
    display: none;
  }

  .vote-loading {
    text-align: center;
    margin-left: 57vh;
    margin-top: 20vh;
  }

  .nav-mobile {
    display: initial;
  }

  .about-top-card {
    background-color: #171717;
    height: 70vh;
    padding: 3em;
    text-align: center;
  }

  .div_three_mobile-flow {
    display: none;
  }

  .web-flow {
    display: initial;
    background-color: "black";
  }
}

@media (min-width: 1024px) {
  #pitch-text {
    font-size: 1.9rem;
  }
}

/*###Tablet(medium)###*/
@media screen and (min-width: 780px) and (max-width: 1023px) {
  /*Style*/
  body {
    margin: 0;
  }

  #pitch-text {
    font-size: 1.9rem;
  }

  .timer {
    flex-direction: column;
    justify-content: center;
  }

  .modal-title {
    padding: 5px;
    font-size: 24px;
  }

  .modal-div {
    padding: 30px;
  }

  .modal-vote-div {
    padding: 40px;
  }

  .modal-vote-btn {
    margin-top: 20px;
    margin-left: 270px;
  }

  .app {
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }
  .btn-text {
    color: white;
    text-decoration: none !important;
  }

  .btn-text:hover {
    color: white;
    text-decoration: none !important;
  }

  .div_one {
    height: 450px;
  }

  .title {
    font-size: 52px;
    padding: 1em;
    font-family: "Bauer Bodoni" !important;
    font-weight: bold;
    font-style: italic;
  }

  .item {
    color: white;
    text-decoration: none !important;
  }
  .item:hover {
    color: #827b5a;
  }

  .logo-light {
    margin-left: 20px;
    margin-top: 5px;
  }

  .div_two {
    background-color: grey;
  }
  /*79705db3*/
  .bg-overlay {
    background: linear-gradient(
        rgba(121, 112, 93, 0.7),
        rgba(121, 112, 93, 0.7)
      ),
      url("/dapper_men.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 0px;
    margin: 0px;
    /*height: 600px;*/
  }

  .text1 {
    margin-left: -40px;
    font-size: 26px;
    margin-top: 150px;
    font-family: "Avenir Black";
  }

  .text2 {
    font-size: 26px;
    margin-top: 20px;
    margin-left: -30px;
    font-family: "Avenir Black";
  }

  .text3 {
    font-size: 26px;
    margin-top: 20px;
    margin-left: -50px;
    font-family: "Avenir Black";
  }

  .vote-btn {
    margin-top: 20px;
    margin-left: 200px;
  }

  .div_three {
    background-color: #000;
    height: 700px;
    clear: both;
  }

  .services-section {
    padding: 5em;
  }

  .services-section img {
    width: 130vh;
    height: 100vh;
    margin: auto;
    display: block;
    object-fit: contain;
  }

  .services-section-img {
    width: 110vh;
    height: 100vh;
    margin: auto;
    display: block;
    text-align: center;
    object-fit: contain;
  }

  .services-header {
    color: rgb(107, 171, 172);
    padding-bottom: 1.5em;
    text-align: center;
    margin: auto;
    display: block;
  }

  .services-paragraph {
    padding-top: 1em;
    font-size: 18px;
    font-weight: lighter;
    color: #ffffff;
    text-align: center;
    margin: auto;
    display: block;
  }

  /* Container holding the image and the text title  */

  /* Bottom left text */
  .bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
  }

  /* Top left text */
  .top-left {
    position: absolute;
    top: 8px;
    left: 16px;
  }

  /* Top right text */
  .top-right {
    position: absolute;
    top: 8px;
    right: 16px;
  }

  /* Bottom right text */
  .bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
  }

  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .box {
    margin: 50px auto;
    margin-top: 70px;
    width: 300px;
    height: 50px;
  }

  .box2 {
    margin: 10px auto;
    margin-top: 65px;
    margin-left: 65px;
    width: 300px;
    height: 50px;
  }

  .container-4 {
    margin-left: -100px;
    overflow: hidden;
    width: 400px;
    vertical-align: middle;
    white-space: nowrap;
  }

  .container-4 input#search {
    width: 350px;
    height: 50px;
    background: #e9e0cfb3;
    border-top: 1px solid #0a0a0a;
    border-bottom: 1px solid #0a0a0a;
    font-size: 10pt;
    float: left;
    color: #000000;
    padding-left: 15px;
  }

  .container-4 button.icon {
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    border: 1px solid #0a0a0a;
    background: #0a0a0a;
    height: 50px;
    width: 100px;
    color: #fff;
    opacity: 1;
    font-size: 10pt;
    padding-top: 15px;
    margin-left: -50px;
  }

  .container-4:hover button.icon,
  .container-4:active button.icon,
  .container-4:focus button.icon {
    outline: none;
    opacity: 1;
    margin-left: -50px;
  }

  .container-4:hover button.icon:hover {
    background: #0a0a0a;
    color: #fff;
  }

  .footer-title {
    font-size: 48px;
    margin-top: 5px;
  }

  .footer-text {
    font-size: 16px;
    margin-left: 20px;
  }

  .footer-text2 {
    font-size: 14px;
  }

  .footer-text3 {
    font-size: 12px;
  }

  .footer-logo {
    margin-top: 60px;
    width: 80px;
  }

  .phone {
    margin-top: 50px;

    width: 250px;
    height: 500px;
  }

  .about-title {
    font-size: 48px;
    padding: 1.5em;
    color: #fff;
    font-family: "Bauer Bodoni";
    font-weight: bold;
    font-style: italic;
  }

  .item-other {
    color: #0a0a0a;
    text-decoration: none !important;
  }
  .item-other:hover {
    color: rgb(202, 197, 197);
  }

  .about-div_one_text {
    font-size: 30px;
    text-align: center;
  }

  .about-people {
    height: 100vh;
  }

  .about-card {
    margin-top: -20px;
  }

  .about-people-title {
    text-align: center;
    font-size: 40px;
    margin-top: 60px;
  }

  .about-section {
    border: 1px solid black;
    margin-top: 20px;
  }

  .about-inner-section {
    border: 1px solid black;
    margin: 40px;
    width: 35vh;
    height: 54vh;
  }

  .container-fostrap {
    display: table-cell;
    padding: 0.5em;
    text-align: center;
    vertical-align: middle;
  }

  .about-people-name {
    font-size: 16px;
  }

  .about-people-location {
    font-size: 14px;
  }

  .about-people-reason {
    font-size: 13px;
  }

  .about-section img {
    width: 150px;
    height: 150px;
    margin: auto;
    display: block;
  }

  .about-header {
    color: rgb(107, 171, 172);
    padding-bottom: 1.5em;
    text-align: center;
    margin: auto;
    display: block;
  }

  .about-paragraph {
    padding-top: 1em;
    font-size: 18px;
    font-weight: lighter;
    color: #0a0a0a;
    text-align: center;
    margin: auto;
    display: block;
  }

  .vote-title {
    color: #171717;
    font-family: "Avenir Black";
    padding: 1vh;
    margin-left: 60vh;
    text-align: center;
  }

  .vote-arrow-left {
    padding: 16vh;
    margin-left: 5vh;
    font-size: 60px;
    margin-top: 4vh;
    position: fixed;
  }

  .vote-arrow-right {
    margin-top: 16vh;
    padding: 4vh;
    margin-left: 9vh;
    font-size: 60px;
    position: fixed;
  }

  .vote-thumbs-down {
    font-size: 50px;
    margin-left: 27vh;
    margin-top: 57vh;
    position: absolute;
  }

  .vote-thumbs-up {
    font-size: 50px;
    margin-left: 19vh;
    margin-top: 57vh;
    position: absolute;
  }

  .vote-img {
    margin-left: 35vh;
    width: 50vh;
  }

  .vote-section-mobile {
    display: none;
  }

  .vote-loading {
    text-align: center;
    margin-left: 57vh;
    margin-top: 20vh;
  }
  .v-mobile {
    display: none;
  }
  .about-top-card {
    background-color: #171717;
    height: 70vh;
    padding: 3em;
    text-align: center;
  }

  .div_three_mobile-flow {
    display: none;
  }

  .web-flow {
    display: initial;
    background-color: "black";
  }
}

/*### Smartphones (portrait and landscape)(small)###  this.state.cards.length === 0 &&*/
@media screen and (min-width: 454px) and (max-width: 780px) {
  /*Style*/
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
  .app {
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }
  .div_one {
    height: 450px;
  }

  .title {
    font-size: 38px;
    padding: 0.5em;
    font-family: "Bauer Bodoni" !important;
    font-weight: bold;
    font-style: italic;
  }

  .phone {
    display: none;
  }

  .phone2 {
    margin-top: 50px;
    width: 250px;
  }

  .text1 {
    margin-left: 10px;
    font-size: 20px;
    margin-top: 100px;
    text-align: center;
    font-family: "Avenir Black" !important;
  }

  .text2 {
    font-size: 20px;
    margin-top: 30px;
    margin-left: 30px;
    text-align: center;
    font-family: "Avenir Black" !important;
  }

  .text3 {
    font-size: 20px;
    margin-top: 30px;
    margin-left: 30px;
    text-align: center;
    font-family: "Avenir Black" !important;
  }

  .vote-btn {
    margin-top: 30px;
    text-align: center;
    margin-left: 100px;
  }

  .div_three {
    display: none;
  }

  .services-section-img {
    width: 550px;
    height: 500px;
    margin: auto;
    display: block;
    object-fit: contain;
  }

  .box {
    margin: 50px auto;
    margin-top: 10px;
    width: 300px;
    height: 50px;
  }

  .box2 {
    margin: 10px auto;
    margin-top: 5px;
    margin-left: 65px;
    width: 400px;
    height: 50px;
  }

  .container-4 {
    overflow: hidden;
    margin-left: -5px;
    width: 380px;
    vertical-align: middle;
    white-space: nowrap;
  }

  .container-4 input#search {
    width: 250px;
    height: 50px;
    background: #e9e0cfb3;
    border-top: 1px solid #0a0a0a;
    border-bottom: 1px solid #0a0a0a;
    font-size: 10pt;
    float: left;
    color: #000000;
    padding-left: 15px;
  }

  .container-4 button.icon {
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    border: 1px solid #0a0a0a;
    background: #0a0a0a;
    height: 50px;
    width: 100px;
    color: #fff;
    opacity: 1;
    font-size: 10pt;
    padding-top: 15px;
    margin-left: -50px;
  }

  .container-4:hover button.icon,
  .container-4:active button.icon,
  .container-4:focus button.icon {
    outline: none;
    opacity: 1;
    margin-left: -50px;
  }

  .container-4:hover button.icon:hover {
    background: #0a0a0a;
    color: #fff;
  }

  .footer-title {
    font-size: 48px;
    margin-top: 5px;
  }

  .footer-logo {
    margin-top: 15px;
    margin-left: 110px;
    width: 80px;
  }

  .footer-text {
    font-size: 15.9px;
  }

  .footer-text2 {
    font-size: 12px;
    margin-left: 39px;
  }

  .footer-text3 {
    font-size: 12px;
    margin-left: 39px;
  }

  .modal-div {
    padding: 10px;
  }

  .about-title {
    font-size: 38px;
    padding: 1.5em;
    color: #fff;
    font-family: "Bauer Bodoni";
    font-weight: bold;
    font-style: italic;
    height: 2000;
  }

  .about-people {
    height: 150vh;
    text-align: center;
  }

  .about-people-title {
    margin-top: 60px;
    font-size: 40px;
    padding: 30px;
    margin-left: -50px;
  }

  .about-card {
    text-align: center;
    margin-left: 50px;
  }

  .about-section {
    border: 1px solid black;
    margin-top: 20px;
    text-align: center;
  }

  .about-inner-section {
    border: 1px solid black;
    margin: 40px;
    width: 32vh;
    height: 50vh;
  }

  .container-fostrap {
    display: table-cell;
    padding: 0.1em;
    text-align: center;
    vertical-align: middle;
  }

  .about-people-name {
    font-size: 14px;
  }

  .about-people-location {
    font-size: 14px;
  }

  .about-people-reason {
    font-size: 12px;
  }

  .about-section img {
    width: 150px;
    height: 150px;
    margin: auto;
    display: block;
  }

  .about-header {
    color: rgb(107, 171, 172);
    padding-bottom: 1.5em;
    text-align: center;
    margin: auto;
    display: block;
  }

  .about-div_one_text {
    font-size: 18px;
    text-align: center;
  }

  .about-paragraph {
    padding-top: 1em;
    font-size: 18px;
    font-weight: lighter;
    color: #0a0a0a;
    text-align: center;
    margin: auto;
    display: block;
  }

  .vote-section {
    display: none;
  }

  .vote-section-mobile {
    color: red;
    font-size: 10px;
  }

  .mobile-img {
    background: linear-gradient(rgba(20, 20, 20, 0.7), rgba(27, 20, 20, 0.7)),
      url("/sewing_machine.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 0px;
    margin: 0px;
  }

  .vote-top-image-card {
    background: linear-gradient(#827b5a, #827b5a);
    color: #fff;
    padding: 0px;
    margin: 0px;
    height: 14vh;
    width: 160vh;
    position: absolute;
  }

  .vote-mobile-text-one {
    margin-left: 5vh;
    position: relative;
    margin-top: 4vh;
    font-size: 20px;
    font-family: "Bauer Bodoni";
    font-style: italic;
  }

  .vote-mobile-text-two {
    font-size: 20px;
    font-family: "Bauer Bodoni";
    font-style: italic;
    text-align: center;
  }

  .vote-div-one-img {
    margin: 0px;
    margin-left: 40vh;
    margin-top: -8vh;
    position: relative;
    width: 25vh;
    height: 20vh;
  }

  .vote-div-two {
    background: linear-gradient(rgba(20, 20, 20, 0.7), rgba(27, 20, 20, 0.7)),
      url("/sewing_machine.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 0px;
    margin: 0px;
    margin-top: 13vh;
    position: relative;
  }

  .vote-img-designs {
    margin-top: 48px;
    width: 40vh;
    margin-left: 20vh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .vote-div-three {
    background: linear-gradient(rgba(130, 123, 90, 1), rgba(130, 123, 90, 1));
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 0px;
    margin: 0px;
    margin-top: 7vh;
    height: 10vh;
  }
  .about-top-card {
    background-color: #171717;
    height: 70vh;
    padding: 3em;
    text-align: center;
  }

  .dot {
    position: absolute;
    margin-left: 100px;
    width: 22vh;
    object-fit: contain;
    margin-top: -70px;
  }
  .div_three_mobile-flow {
    background-color: #171717;
    align-items: center;
    text-align: center;
    height: 58vh;
    display: initial;
  }
  .web-flow {
    display: none;
  }
}

/*### Smartphones (portrait and landscape)(small)### */
@media screen and (min-width: 450px) and (max-width: 763px) {
  /*Style*/
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }

  #pitch-text {
    font-size: 1.2rem;
  }

  .mobile-vote-background {
    background-color: red;
  }
  .div_one {
    height: 450px;
  }

  .title {
    font-size: 38px;
    padding: 0.5em;
    font-family: "Bauer Bodoni" !important;
    font-weight: bold;
    font-style: italic;
  }

  .phone {
    display: none;
  }

  .phone2 {
    margin-top: 50px;
    width: 250px;
  }

  .text1 {
    margin-left: 10px;
    font-size: 20px;
    margin-top: 100px;
    text-align: center;
    font-family: "Avenir Black" !important;
  }

  .text2 {
    font-size: 20px;
    margin-top: 30px;
    margin-left: 10px;
    text-align: center;
    font-family: "Avenir Black" !important;
  }

  .text3 {
    font-size: 20px;
    margin-top: 30px;
    margin-left: 10px;
    text-align: center;
    font-family: "Avenir Black" !important;
  }

  .vote-btn {
    margin-top: 30px;
    margin-left: 150px;
  }

  .div_three {
    display: none;
  }

  .services-section-img {
    width: 450px;
    height: 500px;
    margin: auto;
    display: block;
    object-fit: contain;
  }

  .box {
    margin: 50px auto;
    margin-top: 10px;
    width: 300px;
    height: 50px;
  }

  .box2 {
    margin: 10px auto;
    margin-top: 5px;
    margin-left: 65px;
    width: 400px;
    height: 50px;
  }

  .container-4 {
    overflow: hidden;
    margin-left: -5px;
    width: 380px;
    vertical-align: middle;
    white-space: nowrap;
  }

  .container-4 input#search {
    width: 250px;
    height: 50px;
    background: #e9e0cfb3;
    border-top: 1px solid #0a0a0a;
    border-bottom: 1px solid #0a0a0a;
    font-size: 10pt;
    float: left;
    color: #000000;
    padding-left: 15px;
  }

  .container-4 button.icon {
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    border: 1px solid #0a0a0a;
    background: #0a0a0a;
    height: 50px;
    width: 130px;
    color: #fff;
    opacity: 1;
    font-size: 9pt;
    padding-top: 15px;
    margin-left: -50px;
  }

  .container-4:hover button.icon,
  .container-4:active button.icon,
  .container-4:focus button.icon {
    outline: none;
    opacity: 1;
    margin-left: -50px;
  }

  .container-4:hover button.icon:hover {
    background: #0a0a0a;
    color: #fff;
  }

  .footer-title {
    font-size: 48px;
    margin-top: 5px;
  }

  .footer-logo {
    margin-top: 15px;
    margin-left: 110px;
    width: 80px;
  }

  .footer-text {
    font-size: 15.9px;
  }

  .footer-text2 {
    font-size: 12px;
    margin-left: 39px;
  }

  .footer-text3 {
    font-size: 12px;
    margin-left: 39px;
  }

  .modal-div {
    padding: 10px;
  }

  .about-title {
    font-size: 38px;
    padding: 1.5em;
    color: #fff;
    font-family: "Bauer Bodoni";
    font-weight: bold;
    font-style: italic;
    height: 2000;
  }

  .about-people {
    height: 220vh;
  }

  .about-people-title {
    margin-top: 60px;
    font-size: 40px;
    padding: 30px;
    margin-left: -50px;
  }

  .about-card {
    text-align: center;
    margin-left: 50px;
  }

  .about-section {
    border: 1px solid black;
    margin-top: 20px;
    text-align: center;
  }

  .about-section img {
    width: 150px;
    height: 150px;
    margin: auto;
    display: block;
  }

  .about-header {
    color: rgb(107, 171, 172);
    padding-bottom: 1.5em;
    text-align: center;
    margin: auto;
    display: block;
  }

  .about-div_one_text {
    font-size: 18px;
    text-align: center;
  }

  .about-paragraph {
    padding-top: 1em;
    font-size: 18px;
    font-weight: lighter;
    color: #0a0a0a;
    text-align: center;
    margin: auto;
    display: block;
  }

  .vote-section {
    display: none;
  }

  .vote-section-mobile {
    display: initial;
  }

  .mobile-img {
    background: linear-gradient(rgba(20, 20, 20, 0.7), rgba(27, 20, 20, 0.7)),
      url("/sewing_machine.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 0px;
    margin: 0px;
  }

  .vote-top-image-card {
    background: linear-gradient(#827b5a, #827b5a);
    color: #fff;
    padding: 0px;
    margin: 0px;
    height: 12vh;
    width: 110%;
    position: absolute;
  }

  .vote-mobile-text-one {
    margin-left: 30vh;
    position: relative;
    margin-top: 4vh;
    font-size: 20px;
    font-family: "Bauer Bodoni";
    font-style: italic;
  }

  .vote-mobile-text-two {
    font-size: 20px;
    font-family: "Bauer Bodoni";
    font-style: italic;
    text-align: center;
  }

  .vote-div-one-img {
    margin: 0px;
    margin-left: -300vh;
    margin-top: -8vh;
    position: absolute;
    width: -25vh;
    height: 8vh;
  }

  .v-mobile {
    background: linear-gradient(rgba(20, 20, 20, 0.7), rgba(27, 20, 20, 0.7)),
      url("/sewing_machine.jpg");
    position: relative;
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 100%;
  }

  .nav-mobile {
    display: none;
  }

  .vote-image {
    display: none;
  }

  .mobile-hamburger {
    color: #fff;
    position: absolute;
    margin-left: -20px;
    margin-top: -17px;
  }
  .about-top-card {
    background-color: #171717;
    height: 100vh;
    padding: 3em;
    text-align: center;
  }

  .about-people-title {
    text-align: center;
    font-size: 40px;
    margin-top: 60px;
  }

  .about-section {
    border: 1px solid black;
    margin-top: 20px;
  }

  .about-inner-section {
    border: 1px solid black;
    margin: 5px;
    width: 39vh;
    height: 43vh;
  }

  .about-people-name {
    font-size: 16px;
  }

  .about-people-location {
    font-size: 14px;
  }

  .about-people-reason {
    font-size: 16x;
  }

  .dot {
    position: absolute;
    margin-left: 100px;
    width: 22vh;
    object-fit: contain;
    margin-top: -70px;
  }

  .div_three_mobile-flow {
    background-color: #171717;
    align-items: center;
    text-align: center;
    height: 58vh;
    display: initial;
  }

  .web-flow {
    display: none;
  }
}

/*### Smartphones (portrait and landscape)(small)### */
@media screen and (min-width: 450px) and (max-width: 575px) {
  /*Style*/
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }

  #pitch-text {
    font-size: 1.2rem;
  }

  .mobile-vote-background {
    background-color: red;
  }
  .div_one {
    height: 450px;
  }

  .title {
    font-size: 38px;
    padding: 0.5em;
    font-family: "Bauer Bodoni" !important;
    font-weight: bold;
    font-style: italic;
  }

  .phone {
    display: none;
  }

  .phone2 {
    margin-top: 50px;
    width: 250px;
  }

  .text1 {
    margin-left: 10px;
    font-size: 20px;
    margin-top: 100px;
    text-align: center;
    font-family: "Avenir Black" !important;
  }

  .text2 {
    font-size: 20px;
    margin-top: 30px;
    margin-left: 10px;
    text-align: center;
    font-family: "Avenir Black" !important;
  }

  .text3 {
    font-size: 20px;
    margin-top: 30px;
    margin-left: 10px;
    text-align: center;
    font-family: "Avenir Black" !important;
  }

  .vote-btn {
    margin-top: 60px;
    margin-left: -15px;
  }

  .div_three {
    display: none;
  }

  .services-section-img {
    width: 450px;
    height: 500px;
    margin: auto;
    display: block;
    object-fit: contain;
  }

  .box {
    margin: 50px auto;
    margin-top: 10px;
    width: 300px;
    height: 50px;
  }

  .box2 {
    margin: 10px auto;
    margin-top: 5px;
    margin-left: 65px;
    width: 400px;
    height: 50px;
  }

  .container-4 {
    overflow: hidden;
    margin-left: -5px;
    width: 380px;
    vertical-align: middle;
    white-space: nowrap;
  }

  .container-4 input#search {
    width: 250px;
    height: 50px;
    background: #e9e0cfb3;
    border-top: 1px solid #0a0a0a;
    border-bottom: 1px solid #0a0a0a;
    font-size: 10pt;
    float: left;
    color: #000000;
    padding-left: 15px;
  }

  .container-4 button.icon {
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    border: 1px solid #0a0a0a;
    background: #0a0a0a;
    height: 50px;
    width: 130px;
    color: #fff;
    opacity: 1;
    font-size: 9pt;
    padding-top: 15px;
    margin-left: -50px;
  }

  .container-4:hover button.icon,
  .container-4:active button.icon,
  .container-4:focus button.icon {
    outline: none;
    opacity: 1;
    margin-left: -50px;
  }

  .container-4:hover button.icon:hover {
    background: #0a0a0a;
    color: #fff;
  }

  .footer-title {
    font-size: 48px;
    margin-top: 5px;
  }

  .footer-logo {
    margin-top: 15px;
    margin-left: 110px;
    width: 80px;
  }

  .footer-text {
    font-size: 15.9px;
  }

  .footer-text2 {
    font-size: 12px;
    margin-left: 39px;
  }

  .footer-text3 {
    font-size: 12px;
    margin-left: 39px;
  }

  .modal-div {
    padding: 10px;
  }

  .about-title {
    font-size: 38px;
    padding: 1.5em;
    color: #fff;
    font-family: "Bauer Bodoni";
    font-weight: bold;
    font-style: italic;
    height: 2000;
  }

  .about-people {
    height: 220vh;
  }

  .about-people-title {
    margin-top: 60px;
    font-size: 40px;
    padding: 30px;
    margin-left: -50px;
  }

  .about-card {
    text-align: center;
    margin-left: 50px;
  }

  .about-section img {
    width: 150px;
    height: 150px;
    margin: auto;
    display: block;
  }

  .about-header {
    color: rgb(107, 171, 172);
    padding-bottom: 1.5em;
    text-align: center;
    margin: auto;
    display: block;
  }

  .about-div_one_text {
    font-size: 18px;
    text-align: center;
  }

  .about-paragraph {
    padding-top: 1em;
    font-size: 18px;
    font-weight: lighter;
    color: #0a0a0a;
    text-align: center;
    margin: auto;
    display: block;
  }

  .vote-section {
    display: none;
  }

  .vote-section-mobile {
    display: initial;
  }

  .mobile-img {
    background: linear-gradient(rgba(20, 20, 20, 0.7), rgba(27, 20, 20, 0.7)),
      url("/sewing_machine.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 0px;
    margin: 0px;
  }

  .vote-top-image-card {
    background: linear-gradient(#827b5a, #827b5a);
    color: #fff;
    padding: 0px;
    margin: 0px;
    height: 12vh;
    width: 110%;
    position: absolute;
  }

  .vote-mobile-text-one {
    margin-left: 30vh;
    position: relative;
    margin-top: 4vh;
    font-size: 20px;
    font-family: "Bauer Bodoni";
    font-style: italic;
  }

  .vote-mobile-text-two {
    font-size: 20px;
    font-family: "Bauer Bodoni";
    font-style: italic;
    text-align: center;
  }

  .vote-div-one-img {
    margin: 0px;
    margin-left: -300vh;
    margin-top: -8vh;
    position: absolute;
    width: -25vh;
    height: 8vh;
  }

  .v-mobile {
    background: linear-gradient(rgba(20, 20, 20, 0.7), rgba(27, 20, 20, 0.7)),
      url("/sewing_machine.jpg");
    position: relative;
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 100%;
  }

  .nav-mobile {
    display: none;
  }

  .vote-image {
    display: none;
  }

  .mobile-hamburger {
    color: #fff;
    position: absolute;
    margin-left: -20px;
    margin-top: -17px;
  }
  .about-top-card {
    background-color: #171717;
    height: 100vh;
    padding: 3em;
    text-align: center;
  }

  .about-people-title {
    text-align: center;
    font-size: 40px;
    margin-top: 60px;
  }

  .about-section {
    border: 1px solid black;
    margin-top: 20px;
    text-align: center;
    align-items: center;
  }

  .about-inner-section {
    border: 1px solid black;
    margin: 40px;
    margin-left: 70px;
    height: 60vh;
    width: 57vh;
  }

  .about-people-name {
    font-size: 16px;
  }

  .about-people-location {
    font-size: 14px;
  }

  .about-people-reason {
    font-size: 16px;
  }

  .dot {
    position: absolute;
    margin-left: 105px;
    width: 23vh;
    object-fit: contain;
    margin-top: -70px;
  }

  .div_three_mobile-flow {
    background-color: #171717;
    align-items: center;
    text-align: center;
    height: 98vh;
    display: initial;
  }

  .web-flow {
    display: none;
  }
}

/*### Smartphones (portrait and landscape)(small)###  services-section*/
@media screen and (min-width: 0px) and (max-width: 449px) {
  /*Style*/
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }

  #pitch-text {
    font-size: 1.2rem;
  }

  .mobile-vote-background {
    background-color: red;
  }
  .div_one {
    height: 450px;
  }

  .title {
    font-size: 38px;
    padding: 0.5em;
    font-family: "Bauer Bodoni" !important;
    font-weight: bold;
    font-style: italic;
  }

  .phone {
    display: none;
  }

  .phone2 {
    margin-top: 50px;
    width: 250px;
  }

  .text1 {
    margin-left: 10px;
    font-size: 20px;
    margin-top: 100px;
    text-align: center;
    font-family: "Avenir Black" !important;
  }

  .text2 {
    font-size: 20px;
    margin-top: 30px;
    margin-left: 10px;
    text-align: center;
    font-family: "Avenir Black" !important;
  }

  .text3 {
    font-size: 20px;
    margin-top: 30px;
    margin-left: 10px;
    text-align: center;
    font-family: "Avenir Black" !important;
  }

  .vote-btn {
    margin-top: 60px;
    margin-left: -15px;
  }

  .div_three {
    display: none;
  }

  .services-section-img {
    width: 120vh;
    height: 100vh;
    margin: auto;
    display: block;
    object-fit: contain;
  }

  .box {
    margin: 50px auto;
    margin-top: 10px;
    width: 300px;
    height: 50px;
  }

  .box2 {
    margin: 10px auto;
    margin-top: 5px;
    margin-left: 65px;
    width: 400px;
    height: 50px;
  }

  .container-4 {
    overflow: hidden;
    margin-left: -5px;
    width: 380px;
    vertical-align: middle;
    white-space: nowrap;
  }

  .container-4 input#search {
    width: 250px;
    height: 50px;
    background: #e9e0cfb3;
    border-top: 1px solid #0a0a0a;
    border-bottom: 1px solid #0a0a0a;
    font-size: 10pt;
    float: left;
    color: #000000;
    padding-left: 15px;
  }

  .container-4 button.icon {
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    border: 1px solid #0a0a0a;
    background: #0a0a0a;
    height: 50px;
    width: 130px;
    color: #fff;
    opacity: 1;
    font-size: 9pt;
    padding-top: 15px;
    margin-left: -50px;
  }

  .container-4:hover button.icon,
  .container-4:active button.icon,
  .container-4:focus button.icon {
    outline: none;
    opacity: 1;
    margin-left: -50px;
  }

  .container-4:hover button.icon:hover {
    background: #0a0a0a;
    color: #fff;
  }

  .footer-title {
    font-size: 48px;
    margin-top: 5px;
  }

  .footer-logo {
    margin-top: 15px;
    margin-left: 110px;
    width: 80px;
  }

  .footer-text {
    font-size: 15.9px;
  }

  .footer-text2 {
    font-size: 12px;
    margin-left: 39px;
  }

  .footer-text3 {
    font-size: 12px;
    margin-left: 39px;
  }

  .modal-div {
    padding: 10px;
  }

  .about-title {
    font-size: 38px;
    padding: 1.5em;
    color: #fff;
    font-family: "Bauer Bodoni";
    font-weight: bold;
    font-style: italic;
    height: 2000;
  }

  .about-people {
    height: 220vh;
  }

  .about-people-title {
    margin-top: 60px;
    font-size: 40px;
    padding: 30px;
    margin-left: -50px;
  }

  .about-card {
    text-align: center;
    margin-left: 50px;
  }

  .about-section {
    border: 1px solid black;
    margin-top: 20px;
    text-align: center;
  }

  .about-inner-section {
    border: 1px solid black;
    padding: 40px;
    margin-top: 30px;
    margin-left: 30px;
    width: 250px;
    height: 300px;
  }

  .about-section img {
    width: 150px;
    height: 150px;
    margin: auto;
    display: block;
  }

  .about-header {
    color: rgb(107, 171, 172);
    padding-bottom: 1.5em;
    text-align: center;
    margin: auto;
    display: block;
  }

  .about-div_one_text {
    font-size: 18px;
    text-align: center;
  }

  .about-paragraph {
    padding-top: 1em;
    font-size: 18px;
    font-weight: lighter;
    color: #0a0a0a;
    text-align: center;
    margin: auto;
    display: block;
  }

  .vote-section {
    display: none;
  }

  .vote-section-mobile {
    display: initial;
  }

  .mobile-img {
    background: linear-gradient(rgba(20, 20, 20, 0.7), rgba(27, 20, 20, 0.7)),
      url("/sewing_machine.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 0px;
    margin: 0px;
  }

  .vote-top-image-card {
    background: linear-gradient(#827b5a, #827b5a);
    color: #fff;
    padding: 0px;
    margin: 0px;
    height: 12vh;
    width: 110%;
    position: absolute;
  }

  .vote-mobile-text-one {
    margin-left: 30vh;
    position: relative;
    margin-top: 4vh;
    font-size: 20px;
    font-family: "Bauer Bodoni";
    font-style: italic;
  }

  .vote-mobile-text-two {
    font-size: 20px;
    font-family: "Bauer Bodoni";
    font-style: italic;
    text-align: center;
  }

  .vote-div-one-img {
    margin: 0px;
    margin-left: -300vh;
    margin-top: -8vh;
    position: absolute;
    width: -25vh;
    height: 8vh;
  }

  .v-mobile {
    position: relative;
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 100%;
  }

  .nav-mobile {
    display: none;
  }

  .vote-image {
    display: none;
  }

  .mobile-hamburger {
    color: #fff;
    position: absolute;
    margin-left: -20px;
    margin-top: -17px;
  }
  .about-top-card {
    background-color: #171717;
    height: 100vh;
    padding: 3em;
    text-align: center;
  }

  .about-people-title {
    text-align: center;
    font-size: 40px;
    margin-top: 60px;
  }

  .about-section {
    border: 1px solid black;
    margin-top: 20px;
  }

  .about-inner-section {
    border: 1px solid black;
    margin-top: 10px;
    height: 45vh;
    width: 45vh;
  }

  .about-people-name {
    font-size: 16px;
  }

  .about-people-location {
    font-size: 14px;
  }

  .about-people-reason {
    font-size: 14px;
  }

  .dot {
    position: absolute;
    margin-left: 100px;
    width: 22vh;
    object-fit: contain;
    margin-top: -70px;
  }

  .div_three_mobile-flow {
    background-color: #171717;
    align-items: center;
    text-align: center;
    height: 98vh;
    display: initial;
  }

  .web-flow {
    display: none;
  }
}

@media only screen and (device-width: 768px) and (device-height: 1024px) {
  .about-inner-section {
    border: 1px solid black;
    margin: 2px;
    height: 45vh;
    width: 22.8vh;
  }

  .about-people-name {
    font-size: 16px;
  }

  .about-people-location {
    font-size: 14px;
  }

  .about-people-reason {
    font-size: 14px;
  }
}

/* iPhone11   */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .dot {
    position: absolute;
    margin-left: 85px;
    width: 20vh;
    object-fit: contain;
    margin-top: -70px;
  }

  .about-top-card {
    background-color: #171717;
    height: 98vh;
    padding: 4em;
    text-align: center;
  }

  .about-people-title {
    text-align: center;
    font-size: 30px;
    margin-top: 60px;
  }

  .about-section {
    border: 1px solid black;
    margin-top: 20px;
  }

  .about-inner-section {
    border: 1px solid black;
    margin: 2px;
    height: 62vh;
    width: 39vh;
  }

  .about-people-name {
    font-size: 22px;
  }

  .about-people-location {
    font-size: 20px;
  }

  .about-people-reason {
    font-size: 22px;
  }

  .div_three_mobile-flow {
    display: initial;
    background-color: #171717;
    height: 98vh;
  }
}

@media only screen and (device-width: 411px) and (device-height: 823px) {
  .design-size {
    width: 335px;
  }
  .about-people {
    height: 150vh;
  }

  .about-top-card {
    background-color: #171717;
    height: 98vh;
    padding: 4em;
    text-align: center;
  }

  .about-people-title {
    text-align: center;
    font-size: 30px;
    margin-top: 60px;
  }

  .about-section {
    border: 1px solid black;
    margin-top: 20px;
  }

  .about-inner-section {
    border: 1px solid black;
    margin: 5px;
    height: 62vh;
    width: 42vh;
  }

  .about-people-name {
    font-size: 22px;
  }

  .about-people-location {
    font-size: 20px;
  }

  .about-people-reason {
    font-size: 22px;
  }

  .cutso-mission-header {
    color: white !important;
    font-size: 39px !important;
  }
  .dot {
    position: absolute;
    margin-left: 100px;
    width: 20vh;
    object-fit: contain;
    margin-top: -70px;
  }
}

@media only screen and (device-width: 1024px) and (device-height: 1366px) {
  .about-top-card {
    background-color: #171717;
    height: 52vh;
    padding: 4em;
    text-align: center;
  }

  .about-people-title {
    text-align: center;
    font-size: 40px;
    margin-top: 60px;
  }

  .about-section {
    border: 1px solid black;
    margin-top: 20px;
  }

  .about-inner-section {
    border: 1px solid black;
    margin: 8px;
    height: 45vh;
    width: 22vh;
  }

  .about-people-location {
    font-size: 14px;
  }

  .about-people-reason {
    font-size: 20px;
  }

  .dot {
    position: absolute;
    margin-left: 100px;
    width: 22vh;
    object-fit: contain;
    margin-top: -70px;
  }

  .services-section-img {
    width: 70vh;
    height: 50vh;
    margin: auto;
    display: block;
    object-fit: contain;
  }
}

@media only screen and (device-width: 411px) and (device-height: 823px) {
  .design-size {
    width: 335px;
  }
  .about-people {
    height: 150vh;
  }

  .about-top-card {
    background-color: #171717;
    height: 98vh;
    padding: 4em;
    text-align: center;
  }

  .about-people-title {
    text-align: center;
    font-size: 30px;
    margin-top: 60px;
  }

  .about-section {
    border: 1px solid black;
    margin-top: 20px;
  }

  .about-inner-section {
    border: 1px solid black;
    margin: 5px;
    height: 62vh;
    width: 42vh;
  }

  .about-people-name {
    font-size: 22px;
  }

  .about-people-location {
    font-size: 20px;
  }

  .about-people-reason {
    font-size: 22px;
  }

  .cutso-mission-header {
    color: white !important;
    font-size: 39px !important;
  }

  .dot {
    position: absolute;
    margin-left: 87px;
    width: 22vh;
    object-fit: contain;
    margin-top: -70px;
  }
}

@media only screen and (device-width: 414px) and (device-height: 736px) {
  .about-top-card {
    background-color: #171717;
    height: 92vh;
    padding: 4em;
    text-align: center;
  }

  .about-people-title {
    text-align: center;
    font-size: 40px;
    margin-top: 60px;
  }

  .about-section {
    border: 1px solid black;
    margin-top: 20px;
  }

  .about-inner-section {
    border: 1px solid black;
    margin: 8px;
    height: 56vh;
    width: 47vh;
  }

  .about-people-location {
    font-size: 14px;
  }

  .about-people-reason {
    font-size: 20px;
  }

  .dot {
    position: absolute;
    margin-left: 95px;
    width: 22vh;
    object-fit: contain;
    margin-top: -70px;
  }
}

@media only screen and (device-width: 411px) and (device-height: 731px) {
  .design-size {
    width: 335px;
  }
  .about-people {
    height: 150vh;
  }

  .about-top-card {
    background-color: #171717;
    height: 98vh;
    padding: 4em;
    text-align: center;
  }

  .about-people-title {
    text-align: center;
    font-size: 30px;
    margin-top: 60px;
  }

  .about-section {
    border: 1px solid black;
    margin-top: 20px;
  }

  .about-inner-section {
    border: 1px solid black;
    margin: 5px;
    height: 62vh;
    width: 46vh;
  }

  .about-people-location {
    font-size: 14px;
  }

  .about-people-reason {
    font-size: 18px;
  }

  .cutso-mission-header {
    color: white !important;
    font-size: 39px !important;
  }
  .dot {
    position: absolute;
    margin-left: 100px;
    width: 22vh;
    object-fit: contain;
    margin-top: -70px;
  }
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .vote-mobile-text-one {
    margin-left: 20vh;
    position: relative;
    margin-top: 4vh;
    font-size: 20px;
    letter-spacing: 0.1em;
    font-family: "Bauer Bodoni";
    font-style: italic;
  }
  .about-people {
    height: 150vh;
  }
  .dot {
    position: absolute;
    margin-left: 86px;
    width: 22vh;
    object-fit: contain;
    margin-top: -70px;
  }
}

@media only screen and (device-width: 375px) and (device-height: 667px) {
  .about-top-card {
    background-color: #171717;
    height: 118vh;
    padding: 4em;
    text-align: center;
  }

  .about-people-title {
    text-align: center;
    font-size: 30px;
    margin-top: 60px;
  }

  .about-section {
    border: 1px solid black;
    margin-top: 20px;
  }

  .about-inner-section {
    border: 1px solid black;
    margin: 5px;
    height: 62vh;
    width: 46vh;
  }

  .about-people-location {
    font-size: 14px;
  }

  .about-people-reason {
    font-size: 18px;
  }

  .cutso-mission-header {
    color: white !important;
    font-size: 30px !important;
  }
  .dot {
    position: absolute;
    margin-left: 100px;
    width: 22vh;
    object-fit: contain;
    margin-top: -70px;
  }
}

@media only screen and (device-width: 360px) and (device-height: 640px) {
  .design-size {
    width: 335px;
  }
  .about-people {
    height: 150vh;
  }

  .about-top-card {
    background-color: #171717;
    height: 118vh;
    padding: 4em;
    text-align: center;
  }

  .about-people-title {
    text-align: center;
    font-size: 30px;
    margin-top: 60px;
  }

  .about-section {
    border: 1px solid black;
    margin-top: 20px;
  }

  .about-inner-section {
    border: 1px solid black;
    margin: 5px;
    height: 62vh;
    width: 46vh;
  }

  .about-people-location {
    font-size: 14px;
  }

  .about-people-reason {
    font-size: 16.5px;
  }

  .cutso-mission-header {
    color: white !important;
    font-size: 39px !important;
  }
  .dot {
    position: absolute;
    margin-left: 100px;
    width: 22vh;
    object-fit: contain;
    margin-top: -70px;
  }
}

@media only screen and (device-width: 320px) and (device-height: 568px) {
  .design-size {
    width: 280px;
  }
  .up-rotate-icon {
    width: 4000px;
  }

  .up-icon {
    width: 40px;
    margin-top: -70px;
  }

  .about-top-card {
    background-color: #171717;
    height: 115vh;
    padding: 2em;
    text-align: center;
  }

  .about-people-title {
    text-align: center;
    font-size: 20px;
    margin-top: 60px;
  }

  .about-section {
    border: 1px solid black;
    margin-top: 20px;
  }

  .about-inner-section {
    border: 1px solid black;
    margin: 5px;
    height: 62vh;
    width: 44vh;
  }

  .about-people-name {
    font-size: 16px;
  }

  .about-people-location {
    font-size: 14px;
  }

  .about-people-reason {
    font-size: 14px;
  }

  .cutso-mission-header {
    color: white !important;
    font-size: 39px !important;
  }

  .dot {
    position: absolute;
    margin-left: 100px;
    width: 26vh;
    object-fit: contain;
    margin-top: -70px;
  }
}

@font-face {
  font-family: "Avenir Black";
  src: url("./Avenir-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Bauer Bodoni";
  font-style: italic;
  font-weight: normal;
  src: url("./bauer-bodoni-std-1/BauerBodoniStd-Italic.otf") format("opentype");
}

@font-face {
  font-family: "Bauer Bodoni";
  font-style: italic;
  font-weight: bold;
  src: url("./bauer-bodoni-std-1/BauerBodoniStd-BoldItalic.otf")
    format("opentype");
}

.container {
    width: 25%;
    color: #fff;
    margin: 50px;
  }

  .BourBodoni {
    font-family: 'Bauer Bodoni';
  }
  
  .step {
    padding: 5px;
  
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  
    background-color: cream;
    margin-top: 25px;
  }
  
  .v-stepper {
    position: relative;
    
    /*   visibility: visible; */
  }
  
  /* regular step */
  .step .circle1 {
    border: 3px dotted gray;
    border-radius: 100%;
    width: 30px; /* +6 for border */
    height: 30px;
    display: inline-block;

  }

  .step .circle2 {
    border: 3px dotted gray;
    border-radius: 100%;
    width: 30px; /* +6 for border */
    height: 30px;
    display: inline-block;

  }

  .step .circle3 {
    border: 3px dotted gray;
    border-radius: 100%;
    width: 30px; /* +6 for border */
    height: 30px;
    display: inline-block;

  }

  .step .circle4 {
    border: 3px dotted gray;
    border-radius: 100%;
    width: 30px; /* +6 for border */
    height: 30px;
    display: inline-block;

  }

  .step .circle5 {
    border: 3px dotted gray;
    border-radius: 100%;
    width: 30px; /* +6 for border */
    height: 30px;
    display: inline-block;

  }
  
  .step .line {
    top: 30px;
    left: 14px;
    /*   height: 120px; */
    height: 100%;
    position: absolute;
    border-left: 1px dotted gray;
  }
  
  .step.completed .circle1 {
    visibility: visible;
    background-color: #413d34;
    border-color: #413d34;
  }
  
  .step.completed .circle2 {
    visibility: visible;
    background-color: #5a5647;
    border-color: #5a5647;
  }

  .step.completed .circle3 {
    visibility: visible;
    background-color: #726d57;
    border-color: #726d57;
  }

  .step.completed .circle4 {
    visibility: visible;
    background-color: #807960;
    border-color: #807960;
  }

  .step.completed .circle5 {
    visibility: visible;
    background-color: #a49b7a;
    border-color: #a49b7a;
  }
  .step.completed .line {
    height: 113px;
    border-left: 1.8px dotted rgb(243, 243, 243);
  }
  
  .step:last-child .line {
    border-left: 3px dotted white;
    z-index: -1; /* behind the circle to completely hide */
  }

  
  .content {
    margin-left: 30px;
    margin-top: 0px;
    display: inline-block;
  }
  
  
.arrow {
    width: 400px;
    height: 300px;
}
.slick-dots li button:before {
    color: #fff;
    width: 30px
  
  }

  .arrows {
    width: 100px;
     height:100px;
  }
@media screen and (max-width: 576px) {
    .slick-dots li button:before {
        color: #fff;
        margin-top: -165px;
      }

      .arrows {
        width: 50px;
         height: 50px;
      }
}

